// georgia font
@import url('https://fonts.googleapis.com/css2?family=Georgia:wght@400;700&display=swap');
// open sans font
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');
$primary: #1B4F72;
$secondary: #D1F2EB;
$regular-fields: #007bff;
$bg-color: #E8F6F3;
$gray-100: #fcfbfb !default;
$gray-200: #faf9f8 !default;
$gray-300: #f8f7f5 !default;
$gray-400: #f5f3f1 !default;
$gray-500: #dddbd9 !default;
$gray-600: #c4c2c1 !default;
$gray-700: #acaaa9 !default;
$gray-800: #7b7a79 !default;
$gray-900: #494948 !default;
$black: #231F20 !default;
$text-muted: $gray-700;
$component-active-bg: $secondary;
$input-color: $primary;
$custom-select-bg: $gray-300;
$input-padding-x: 1.3rem;
$input-padding-y: .8rem;
$font-family-sans-serif: 'Open Sans',
sans-serif;
$font-family-serif: 'Georgia',
serif;
$font-size-base: 0.9rem;
$input-font-size: 1.4rem;
$input-font-weight: 500;
$headings-font-weight: 900;
$display1-weight: 500;
$display2-weight: 500;
$display3-weight: 500;
$display4-weight: 500;
$border-radius: 0px;
$badge-font-size: 75%;
$badge-font-weight: 300;
$badge-pill-padding-x: 1.5em;
$badge-padding-y: 0.75em;
$enable-rounded: false;
$enable-responsive-font-sizes: true;
@import "~bootstrap/scss/bootstrap";
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
.display-1,
.display-2,
.display-3,
.display-4 {
    letter-spacing: -0.05rem;
}

input {
    letter-spacing: -0.05rem;
}

.font-sans-serif {
    font-family: $font-family-sans-serif !important;
}

.font-serif {
    font-family: $font-family-serif !important;
}

.font-letter-spacing-loose {
    letter-spacing: 0.01rem;
}

.border-light-2 {
    // border-color: $gray-500;
    border-color: $bg-color;
}

.bg-light-2 {
    // background-color: $gray-400;
    background-color: $bg-color;
}

.navbar-toggler-icon {
    background-size: 75% 75%;
}

.primary-bg {
    background-color: $primary;
}

.primary-text {
    color: $primary;
}

btn:disabled {
    background-color: $gray-500;
}

.truncate-text {
    display: inline-block;
    max-width: 150px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}