@import 'bootstrap';
@import 'app';

.search-result-card {
  min-height: 100px;
  font-size: 12px;

  .modal {
    font-size: 1rem;
  }

  @media (min-width: 768px) {
    min-height: 125px;
  }
}

@media (min-width: 768px) {
  #filters-section.d-md-block {
    display: block !important;
  }
}

a.clickable-search-term {
  color: unset;
  text-decoration-line: underline;
  text-decoration-color: darken($secondary, 40%);
  cursor: pointer;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  row-gap: 10px;

  @media (min-width: 768px) {
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 20px;
    row-gap: 20px;
  }
}

mark {
  color: unset;
  background-color: rgba(darken($secondary, 5%), .7);
  padding: 0 0.08em;
}

.ais-SearchBox-loadingIndicator {
  position: absolute;
  top: 21px;
  right: 60px;
}

.open-book-details{
  cursor: pointer;
  // no underline
  text-decoration: none!important;
}

.open-book-details:hover {
  color: $primary;
  opacity: 0.95;
}

@media (max-width: 1200px) {
  h1, .h1 {
    font-size: calc(1.2rem + 1.2vw);
  }

  h4, .h4 {
    font-size: calc(1.0rem + 0.12vw);
  }
}

.no-underline{
  cursor: pointer;
  text-decoration: none!important;
}


.clickable-search-term:hover {
  cursor: pointer;
}

.sub-links{
  color: gray;
  text-decoration: none;
}

.sub-links:hover{
  text-decoration: underline;
}

.thumbnail-img{
  img{
    width: 100%;
    max-height: 300px;
    object-fit: contain;
    @media screen and (max-width: 768px) {
      width: auto;
    }
  }
}


.truncate-text-authors , .truncate-text-orig-authors{
  // show two lines with 50 characters
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  
}